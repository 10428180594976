// as the page loads, call these scripts
jQuery(document).ready(function($) 
{
	
	var st_nav = {
		
		et_top_menu: $( 'ul.nav' ),
		et_menu_hover_triggered: false,
		et_parent_menu_longpress_limit: 300,
		et_parent_menu_longpress_start: null,
		et_parent_menu_click: true,
		
		init: function ()
		{
			var _this = this;
			
			_this.hover();
			_this.dropdown_mobile_touch();
			_this.mega_menu();
			_this.mobile_prepare();
			_this.search();
			_this.fixes();
			
			//_this.et_top_menu.flexVerticalCenter();
			
			$(window).resize(_this.window_resize);
		},
		
		window_resize: function ()
		{
			st_nav._mobile_width_fix();
		},
		
		
		hover: function ()
		{
			var _this = this;
			
			_this.et_top_menu.find( 'li' ).hover( function() {
				if ( ! $(this).closest( 'li.mega-menu' ).length || $(this).hasClass( 'mega-menu' ) ) {
					$(this).addClass( 'st-show-dropdown' );
					$(this).removeClass( 'st-hover' ).addClass( 'st-hover' );
					_this.et_menu_hover_triggered = true;
				}
			}, function() {
				var $this_el = $(this);

				$this_el.removeClass( 'st-show-dropdown' ).addClass( 'st-dropdown-removing' );

				_this.et_menu_hover_triggered = false;

				setTimeout( function() {
					if ( ! $this_el.hasClass( 'st-show-dropdown' ) ) {
						$this_el.removeClass( 'st-hover' ).removeClass( 'st-dropdown-removing' );
					}
				}, 200 );
			} );
		},
		
		
		dropdown_mobile_touch: function ()
		{
			var _this = this;
			
			// Dropdown menu adjustment for touch screen
			_this.et_top_menu.find('.menu-item-has-children > a').on( 'touchstart', function(){
				_this.et_parent_menu_longpress_start = new Date().getTime();
			} ).on( 'touchend', function(){
				var et_parent_menu_longpress_end = new Date().getTime()
				if ( et_parent_menu_longpress_end  >= _this.et_parent_menu_longpress_start + _this.et_parent_menu_longpress_limit ) {
					_this.et_parent_menu_click = true;
				} else {
					_this.et_parent_menu_click = false;

					// Some devices emulate hover event on touch, so check that hover event was not triggered to avoid extra mouseleave event triggering
					if ( ! _this.et_menu_hover_triggered ) {
						// Close sub-menu if toggled
						var $et_parent_menu = $(this).parent('li');
						
						if ( $et_parent_menu.hasClass( 'st-hover') ) {
							$et_parent_menu.trigger( 'mouseleave' );
						} else {
							$et_parent_menu.trigger( 'mouseenter' );
						}
					}
				}
				_this.et_parent_menu_longpress_start = 0;
			} ).click(function() {
				if ( _this.et_parent_menu_click ) {
					return true;
				}

				return false;
			} );
		},
		
		
		mega_menu: function ()
		{
			var _this = this;
			
			_this.et_top_menu.find( 'li.mega-menu' ).each(function(){
				var $li_mega_menu           = $(this),
					$li_mega_menu_item      = $li_mega_menu.children( 'ul' ).children( 'li' ),
					li_mega_menu_item_count = $li_mega_menu_item.length;

				if ( li_mega_menu_item_count < 4 ) {
					$li_mega_menu.addClass( 'mega-menu-parent mega-menu-parent-' + li_mega_menu_item_count );
				}
			});
		},
		
		

		
		
		mobile_prepare: function ()
		{
			var _this = this;
			
			// function to duplicate menu to another location and register mobile toggle
			window.et_duplicate_menu = function( menu, append_to, menu_id, menu_class, menu_click_event )
			{
				var $this_menu = append_to,
					$cloned_nav;
	
				// make this function work with existing menus, without cloning
				if ( '' !== menu ) 
				{
					menu.clone().attr('id',menu_id).removeClass().attr('class',menu_class).appendTo( $this_menu );
				}
	
				$cloned_nav = $this_menu.find('> ul');
				$cloned_nav.find('.menu_slide').remove();
				$cloned_nav.find('li:first').addClass('et_first_mobile_item');
	
				$cloned_nav.find( 'a' ).on( 'click', function()
				{
					$this_menu.parents('.cbb-nav').find( '.mobile_menu_bar' ).trigger( 'click' );
				});
	
				if ( 'no_click_event' !== menu_click_event ) 
				{
					$this_menu.on( 'click', '.mobile_menu_bar', function()
					{
						var nav_element = $(this).parents('.cbb-nav'),
							mobile_nav = $('.mobile_nav', nav_element);
						
						if ( mobile_nav.hasClass('closed') ){
							
							// beforing opening ,close all others
							$('.cbb-nav .mobile_nav.opened').removeClass( 'opened' ).addClass( 'closed' ).find('> ul').stop().slideUp( 300 );
							
							mobile_nav.removeClass( 'closed' ).addClass( 'opened' );
							mobile_nav.find('> ul').stop().slideDown( 500 );
						} else {
							mobile_nav.removeClass( 'opened' ).addClass( 'closed' );
							mobile_nav.find('> ul').stop().slideUp( 500 );
						}
						return false;
					});
				}
			}

			// clone each navigation for mobile
			var nav_i = 0;
			$('.cbb-nav').each(function ()
			{
				nav_i++;
				et_duplicate_menu( $('ul.nav', this), $('.mobile_nav', this), 'mobile_menu_'+nav_i, 'et_mobile_menu' );
			});	
			_this._mobile_width_fix();
		},
		
		
		_mobile_width_fix: function ()
		{
			$('.cbb-nav .et_mobile_menu').each(function ()
			{
				cbb.main_area = $('#main_area');
				if(cbb.main_area.length < 1) return;
				
				cbb.full_width = cbb.main_area.width();
				cbb.main_area_offset = cbb.main_area.offset().left;
	
				if(cbb.full_margin_direction=='right')
				{
					$(this).css('width', '100%');
				}
							
				$(this).css('margin-'+cbb.full_margin_direction, 0);
		
	
				cbb.margin_left = $(this).parents('.et_mobile_nav_menu_').offset().left - cbb.main_area_offset - 15;
				$(this).width(cbb.full_width - 40).css('margin-'+cbb.full_margin_direction, '-'+cbb.margin_left+'px');
				
			});

		},

		
		search: function ()
		{
			var _this = this;
			
			var $search_container,
				$nav;
				
			// on click show search and set css
			$( '.et_top_search' ).click( function() {
				$nav = $(this).parents('.cbb-nav');
				$search_container = $nav.find( '.et_search_form_container' );
	
				if ( $search_container.hasClass('et_pb_is_animating') ) {
					return;
				}
	
				$nav.removeClass( 'et_pb_menu_visible et_pb_no_animation' ).addClass('et_pb_menu_hidden');
				$search_container.removeClass( 'et_pb_search_form_hidden et_pb_no_animation' ).addClass('et_pb_search_visible et_pb_is_animating');
				setTimeout( function() {
					$nav.addClass( 'et_pb_no_animation' );
					$search_container.addClass( 'et_pb_no_animation' ).removeClass('et_pb_is_animating');
				}, 1000);
				$search_container.find( 'input' ).focus();
	
				_this._search_set_css($nav, $search_container);
			});
	
	
			// close search
			$( '.et_close_search_field' ).click( function() {
				$nav = $(this).parents('.cbb-nav');
				$search_container = $nav.find( '.et_search_form_container' );
				
				_this._search_hide($nav, $search_container);
			});
			
			// close on outside click
			$( document ).mouseup( function(e) {

				$( '.cbb-nav' ).each(function ()
				{
					if(! $(this).hasClass('et_pb_menu_hidden')) return;
					
					if ( ! $(this).is( e.target ) && $(this).has( e.target ).length === 0 )	{
						$search_container = $(this).find( '.et_search_form_container' );
						_this._search_hide( $(this), $search_container );
					}
				});
			});
		},
		
		
		_search_hide: function ($nav, $search_container)
		{
			var _this = this;
			
			if ( $search_container.hasClass('et_pb_is_animating') ) {
				return;
			}

			$nav.removeClass( 'et_pb_menu_hidden et_pb_no_animation' ).addClass( 'et_pb_menu_visible' );
			$search_container.removeClass('et_pb_search_visible et_pb_no_animation' ).addClass( 'et_pb_search_form_hidden et_pb_is_animating' );
			setTimeout( function() {
				$nav.addClass( 'et_pb_no_animation' );
				$search_container.addClass( 'et_pb_no_animation' ).removeClass('et_pb_is_animating');
			}, 1000);
		},
		
		
		_search_set_css: function ($nav, $search_container)
		{
			return;
			
			var _this = this;
			
			if ( $search_container.hasClass( 'et_pb_search_visible' ) ) {
				var header_height = $nav.innerHeight(),
					menu_width = $nav.width();
				$search_container.css( { 'height' : header_height + 'px' } );
				$search_container.find( 'form' ).css( 'max-width', menu_width + 60 );
			}
		},
		
		
		// Add conditional class to prevent unwanted dropdown nav
		fixes: function ()
		{
			var _this = this;
			
			var et_window_width;
			function et_fix_nav_direction() 
			{
				et_window_width = $(window).width();
				$('.nav li.st-reverse-direction-nav').removeClass( 'st-reverse-direction-nav' );
				$('.nav li li ul').each(function(){
					var $dropdown       = $(this),
						dropdown_width  = $dropdown.width(),
						dropdown_offset = $dropdown.offset(),
						$parents        = $dropdown.parents('.nav > li');

					if ( dropdown_offset.left > ( et_window_width - dropdown_width ) ) {
						$parents.addClass( 'st-reverse-direction-nav' );
					}
				});
			}
			et_fix_nav_direction();
		}
		
	} // end cbb

	
	// initiate builder frontend class
	//st_nav.init();

	
}); /* end of as page load scripts */


