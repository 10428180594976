// ------------------------------------------------------
//  THIRDPARTY PLUGINS
// ------------------------------------------------------


// ------------------------------------------------------
//  jquery.scrollsnap.js
// ------------------------------------------------------
!function(n){function t(n,t,a){var e,o,i,s,r;return function(){i=this,o=arguments,s=new Date;var f=function(){var p=new Date-s;t>p?e=setTimeout(f,t-p):(e=null,a||(r=n.apply(i,o)))},p=a&&!e;return e||(e=setTimeout(f,t)),p&&(r=n.apply(i,o)),r}}n.fn.scrollsnap=function(a){var e=n.extend({direction:"y",snaps:"*",proximity:12,offset:0,duration:200,latency:250,easing:"swing",onSnapEvent:"scrollsnap",onSnap:function(){},onSnapWait:50},a),o="x"===e.direction?"Left":"Top",i="offset"+o,s="scroll"+o;return this.each(function(){var a=this,r=n(this);if(1==a.nodeType){r.css("position","relative");var f=null,p=function(){var o=null,p=e.proximity+1;if(r.find(e.snaps).each(function(){var n=this,t=Math.abs(n[i]+e.offset-a[s]);p>t&&(o=n,p=t)}),o){var l=o[i]+e.offset,c={},u=n(o);c[s]=l,Math.abs(r[s]()-l)>2?r.animate(c,e.duration,e.easing,t(function(){e.onSnap&&e.onSnap(u),u.trigger(e.onSnapEvent)},e.onSnapWait)):l!==f&&e.onSnap&&e.onSnap(u,!0),f=l}};r.bind("scrollstop",{latency:e.latency},p)}else if(9==a.nodeType){var l=a.defaultView||a.parentWindow,f=null,p=function(){var i=null,p=e.proximity+1;if(n(a).find(e.snaps).each(function(){var t="undefined"!=typeof l.scrollX?"scroll"+e.direction.toUpperCase():"page"+e.direction.toUpperCase()+"Offset",a=this,s=Math.abs(n(a).offset()[o.toLowerCase()]+e.offset-l[t]);p>s&&(i=a,p=s)}),i){var c=n(i),u=c.offset()[o.toLowerCase()]+e.offset,d={};d[s]=u,Math.abs(r[s]()-u)>2?n("html, body").animate(d,e.duration,e.easing,t(function(){e.onSnap&&e.onSnap(c),c.trigger(e.onSnapEvent)},e.onSnapWait)):u!==f&&e.onSnap&&e.onSnap(c,!0),f=u}};r.bind("scrollstop",{latency:e.latency},p),n(l).bind("resize",p)}})}}(jQuery);


// ------------------------------------------------------
//  jQuery Scrollstop Plugin v1.1.0
// ------------------------------------------------------
!function(t){var n=t.event.dispatch||t.event.handle,e=t.event.special,l="D"+ +new Date,a="D"+(+new Date+1);e.scrollstart={setup:function(a){var s,c=t.extend({latency:e.scrollstop.latency},a),o=function(t){var e=this,l=arguments;s?clearTimeout(s):(t.type="scrollstart",n.apply(e,l)),s=setTimeout(function(){s=null},c.latency)};t(this).bind("scroll",o).data(l,o)},teardown:function(){t(this).unbind("scroll",t(this).data(l))}},e.scrollstop={latency:250,setup:function(l){var s,c=t.extend({latency:e.scrollstop.latency},l),o=function(t){var e=this,l=arguments;s&&clearTimeout(s),s=setTimeout(function(){s=null,t.type="scrollstop",n.apply(e,l)},c.latency)};t(this).bind("scroll",o).data(a,o)},teardown:function(){t(this).unbind("scroll",t(this).data(a))}}}(jQuery);


// ------------------------------------------------------
//  FlexVerticalCenter.js 1.0
// ------------------------------------------------------
!function(e){e.fn.flexVerticalCenter=function(t){var n=e.extend({cssAttribute:"margin-top",verticalOffset:0,parentSelector:null,debounceTimeout:25,deferTilWindowLoad:!1},t||{});return this.each(function(){var t,r=e(this),i=function(){var e=n.parentSelector&&r.parents(n.parentSelector).length?r.parents(n.parentSelector).first().height():r.parent().height();r.css(n.cssAttribute,(e-r.height())/2+parseInt(n.verticalOffset))};e(window).resize(function(){clearTimeout(t),t=setTimeout(i,n.debounceTimeout)}),n.deferTilWindowLoad||i(),e(window).load(function(){i()})})}}(jQuery);