// as the page loads, call these scripts
jQuery(document).ready(function($)
{

		window.cbb = {

		init: function ()
		{
			var _this = this;


			_this.rows();
			_this.columns();
			_this.layout_sidebar();
			_this.fixes();
			_this.layout_adjustments();

			$(window).resize(_this.window_resize);
		},

		window_resize: function ()
		{
			console.log('cbb window_resize');
			cbb.rows_height();
			cbb.row_full_wide_content();
			cbb.layout_adjustments();
		},


		// Adjusts rows. Row full height, full width, vertical center content
		rows: function ()
		{
			// for rtl
			if($('body').hasClass('rtl')) cbb.full_margin_direction = 'right';

			// if page has full width rows then add this class to body to prevent overflow
			if($('.st_fullwidth').length > 0) $('body').addClass('st-full-width');

			cbb.rows_height();
			cbb.row_full_wide_content();

			// vertical center content
			// $(document).scrollsnap({
			// 	snaps: '.row_snap',
			// 	proximity: 150
			// });
			$('.row.row_content_center .row-inner').flexVerticalCenter();
		},


		// makes row full width
		// this method registers itself as resize event
		full_margin_direction: 'left',
		row_full_wide_content: function ()
		{
			cbb.main_area = $('#main_area');
			if(cbb.main_area.length < 1) return;

			cbb.full_width = cbb.main_area.width();
			cbb.main_area_offset = $('#main_area').offset().left;

			if(cbb.full_margin_direction=='right')
			{
				// RTL
				$('.full_wide_content, .slider-size-full, .cbb-full-width').each(function ()
				{
					$(this).css('margin-'+cbb.full_margin_direction, 0); // first, reset
					$(this).width(cbb.full_width);
					$(this).css('margin-'+cbb.full_margin_direction, $(this).offset().left+'px');
				});
			}
			else
			{
				// LTR
				$('.full_wide_content, .slider-size-full, .cbb-full-width').each(function ()
				{
					$(this).css('margin-'+cbb.full_margin_direction, 0);
					cbb.margin_left = $(this).offset().left - cbb.main_area_offset;
					$(this).width(cbb.full_width).css('margin-'+cbb.full_margin_direction, '-'+cbb.margin_left+'px');
				});
			}
		},


		// makes row full window height
		// this method registers itself as resize event
		rows_height: function()
		{
			$('.cbb-row-wrapper .row.row_height_window').each(function ()
			{
				$(this).css('min-height', $(window).height() );
			});
		},


		// Adjusts columns
		columns: function ()
		{
			// if in rows settings columns are set to center content
			$('.cbb-row-col-center > div.col > .col-inner').flexVerticalCenter();
		},


		layout_sidebar: function ()
		{
			if($('body').hasClass('page-template-template-home-sidebar-php') || $('body').hasClass('page-template-template-sidebar-php'))
			{
				var after_slider_load = setInterval(function()
				{
					if($('.page-content > .cbb-row-wrapper:first-child .st-element-revolutionslider:first-child .slider-size-full').length != 0)
					{
						$('#sidebar').css('margin-top', $('.page-content > .cbb-row-wrapper:first-child .st-element-revolutionslider:first-child .slider-size-full').height()+'px');
					}
					else if($('.page-content > .cbb-row-wrapper:first-child .st-element-revolutionslider .slider-size-full').length != 0)
					{
						$('#sidebar').css('margin-top', $('.page-content > .cbb-row-wrapper:first-child').height()+'px');
					}

					clearInterval(after_slider_load)
				},500); //wait 1 seconds, just enough for vc to do all its thing
			}
		},

		layout_adjustments: function () {
			var w, h;
			$('.img-circle-container img').each(function () {
				$(this).removeAttr('style');
				w = $(this).width();
				h = $(this).height();

				if(w > h)
					$(this).height( w );
				else
					$(this).width( h );

				w = $(this).removeAttr('width').removeAttr('height');
			});	
		},


		fixes: function ()
		{
			// ------------------------------------------------------
			//  Remove all empty p tags added by wpautop
			// ------------------------------------------------------
			$('.entry-content p:empty').remove();
		}
	} // end cbb


	// initiate builder frontend class
	cbb.init();


}); /* end of as page load scripts */
